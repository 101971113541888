body {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  background-color: #F4F6F7;
}

p,
label {
  align-self: center;
  font-size: 14px;
  font-weight: 500;
  bottom: 16px;
  color: #1F455E;
}

button {
  border: #1F455E 2px solid;
  margin-top: 28px;
  padding: 6px 24px;
  font-weight: 700 !important;
  color: #1F455E;
  background-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  font-size: 14px;
  line-height: 20px;
}

button:focus {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

section {
  margin-top: 48px;
}

br {
  margin-bottom: 16px;
}

input[type=text] {
  display: flex;
  width: 95%;
  padding-left: 8px;
  padding-right: 8px;
  border: transparent 2px solid;
  border-bottom: #1F455E 2px solid;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  color: #1F455E;
  height: 35px;
  flex: 1;
}

input[type=text]:focus {
  outline: none;
  box-shadow: none;
}

input[type=text]:focus-visible {
  border: #1F455E 1px solid;
  margin: 11px 1px 1px 1px;
}

input[type=radio] {
  height: 20px;
  width: 20px;
  padding: none;
}

input[type="file"] {
  display: none;
}

textarea {
  background-color: transparent;
  border: #1F455E 2px solid;
  color: #1F455E;
  font-family: 'Helvetica Neue';
  font-size: 18px;
  font-weight: 700;
  height: 80px;
  padding: 8px;
  margin-top: 16px;
  overflow: hidden;
  resize: none;
  width: 100%;
}