.App {
  display: flex;
  box-sizing: border-box;
  padding: 5% 10% 15% 10%;
}

.AppContent {
  width: 100%
}

.Headline {
  color: #1D1D1B;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}

.ErrorLabel {
  color: #9D4A46;
  align-self: flex-start;
  margin-top: 20px;
}

.FormSection {
  align-items: center;
  margin-right: 20px;
}

.InputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InputSpacer,
.InputDelete {
  height: 24px;
  width: 24px;
}

.RadioButtonHeader {
  align-self: flex-start;
}

.RadioButtonSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 72px;
}

.RadioButtonContainer {
  display: flex;
  align-self: flex-start;
  position: sticky;
  cursor: pointer;
  font-size: 16;
  margin-top: 15px;
  margin-bottom: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide default styling */
.RadioButtonContainer input {
  position: absolute;
  cursor: pointer;
  appearance: none;
  opacity: 0;
  height: 0;
  width: 0;
}

.RadioButtonLabel :focus,
:focus-visible {
  border: red 2px solid
}

.RadioButton {
  position: absolute;
  border: 2px #1F455E solid;
  top: 16px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.RadioButtonLabel {
  top: 0px;
  left: 36px;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
}

/* On mouse-over, add a grey background color */
.RadioButtonContainer:hover input~.RadioButton {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.RadioButtonContainer input:checked~.RadioButton {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.RadioButton:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.RadioButtonContainer input:checked~.RadioButton:after {
  display: block;
}

/* Checkmark indicator */
.RadioButtonContainer .RadioButton:after {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #1F455E;
}

.SubmitButton {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-appearance: none;
  -webkit-border-radius:0; 
  background-color: #91265F;
  border-radius:0;
  border: none;
  box-sizing: border-box;
  color: white;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  height: 36px;
  margin-top: 32px;
  padding: 6px 48px;
}

.SubmitButton:focus-visible {
  border-radius: 0px;
  border: #1F455E 2px solid;
  outline: none;
}

.FileInput {
  border: #1F455E 2px solid;
  color: #1F455E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 24px;
}

.FileHintLabel {
  color: #2B377D;
}

.FileLabelContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 15px;
}

.FileLabel {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  margin-left: 10px;
  margin-block: 0px;
  color: #1F455E;
}

.FileDelete {
  height: 24px;
  width: 24px;
  margin-left: 24px;
}

.Challenge {
  margin-top: 32px;
  margin-bottom: 24px;
  width: 300px;
}

.frc-banner {
  display: none;
}

.ServerContainer {
  flex-direction: column;
  margin-top: 64px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ServerImage {
  height: 68px;
  width: 68px;
}

.ServerLabel {
  width: 75%;
  text-align: center;
}